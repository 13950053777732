import React from 'react';
import './header.css';
import Logo1 from '../../static/logo-blue-bg.png'; // Directly import the image

function Header() {

  const openNav = () => {
    document.getElementById("mySidebar").style.width = "250px";
  };

  const closeNav = () => {
    document.getElementById("mySidebar").style.width = "0";
  };

  return (
    <header className="header" id="header">
      <a className="logo" href="/">
        <img src={Logo1} alt="Logo" loading="eager" />
      </a>
      <nav>
        <ul>
          <li><a href="/">Inicio</a></li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Organización
            </a>
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" href="/nosotras">Nosotras</a></li>
              <li><a className="dropdown-item" href="/programas">Programas</a></li>
            </ul>
          </li>

          

          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Novedades
            </a>
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" href="/noticias">Noticias</a></li>
              <li><a className="dropdown-item" href="/testimonios">Testimonios</a></li>
              <li><a className="dropdown-item" href="/noticias/news143">WCB Latam</a></li>
            </ul>
          </li>
          <li><a href="/convocatorias">Convocatorias</a></li>
          <li><a className="socio" href="https://redmaestra.donando.cl" target="_blank" rel="noopener noreferrer">Hazte Soci@</a></li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Otros
            </a>
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" href="https://redmaestra.azurewebsites.net/">Intranet</a></li>
              <li><a className="dropdown-item" href="/linktree">LinkTree</a></li>
            </ul>
          </li>
        </ul>
      </nav>

      <div id="mySidebar" className="sidebar">
        <button className="closebtn" onClick={closeNav}>×</button>
        <a href="/">Inicio</a>
        <a href="/nosotras">Organización</a>
        <a href="/programas">Programas</a>      
        <a href="/noticias">Noticias</a>
        <a href="/testimonios">Testimonios</a>
        <a href="/noticias/news143">WCB Latam</a>
        <a href="/convocatorias">Convocatorias</a>
        <a className="socio" href="https://redmaestra.donando.cl" target="_blank" rel="noopener noreferrer">Hazte Soci@</a>
        {/*<a href="https://redmaestra.donando.cl/campanas" target="_blank" rel="noopener noreferrer">Campañas</a>*/}
        <a href="https://redmaestra.azurewebsites.net/">Intranet</a>
        <a href="/linktree">LinkTree</a>
      </div>

      <div id="resnav">
        <button className="openbtn" onClick={openNav}>☰</button>
      </div>
    </header>
  );
}

export default Header;
