import React, { useState, useEffect } from 'react';
import './rrss.css';

function Redes() {
  const [instagramData, setInstagramData] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Fetch Instagram data from the Azure Blob Storage container
  useEffect(() => {
  const fetchInstagramData = async () => {
    try {
      // Add a timestamp to the URL to prevent caching
      const timestamp = new Date().getTime();
      const response = await fetch(`https://cdn-redmaestra-endpoint.azureedge.net/frontend/instagramData.json?t=${timestamp}`);

      if (!response.ok) {
        throw new Error('Failed to fetch Instagram data');
      }

      const data = await response.json();
      setInstagramData(data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError(true);
      setLoading(false);
    }
  };

  fetchInstagramData();
}, []);


  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear().toString().slice(0)}`;
  };

  const handleCardClick = (post) => {
    setSelectedPost(post);
  };

  const handleClose = () => {
    setSelectedPost(null);
  };

  if (loading) {
    return <p>Loading Instagram data...</p>;
  }

  if (error || !instagramData || !instagramData.media || !instagramData.media.data) {
    return <p>Error loading Instagram data.</p>;
  }

  return (
    <>
      <h1>Novedades</h1>
      <div id="rrss_block">
        {instagramData.media.data.map((post, index) => {
          const cardClass = `card rrss rrss${(index % 4) + 1}`; // This will cycle through rrss1, rrss2, rrss3, rrss4

          return (
            <div key={index} className={cardClass} onClick={() => handleCardClick(post)}>
              <img
                src={post.thumbnail_url || post.media_url}
                className="card-img-top"
                alt={post.caption ? post.caption.slice(0, 100) + '...' : 'Instagram Post'}
              />
            </div>
          );
        })}
      </div>

      {selectedPost && (
        <div className="expanded-card-overlay" onClick={handleClose}>
          <div className="expanded-card-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedPost.thumbnail_url || selectedPost.media_url}
              alt={selectedPost.caption ? selectedPost.caption.slice(0, 30) + '...' : 'Instagram Post'}
              className="expanded-card-image"
            />
            <div className="expanded-card-body">
              <h5 className="card-title badge rounded-pill text-bg-secondary">
                {formatDate(selectedPost.timestamp)}
              </h5>
              <p className="card-rrss">{selectedPost.caption}</p>
              <a href={selectedPost.permalink} className="btn btn-primary" target="_blank" rel="noreferrer">Ver Post</a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Redes;
