// 1. Imports
import './detalle.css';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import React, { useEffect, useState } from 'react';
import { Badge, Button } from 'react-bootstrap'; // Import Badge from React-Bootstrap

// 2. NovItem Component - State and Helper Functions
function NovItem() {
  const { itemId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [itemData, setItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // 3. Navigation Function
  const goBack = () => navigate(-1);

  // 5. Load Item Data on Mount
  useEffect(() => {
    window.scrollTo(0, 0);
    const loadItemData = async () => {
      setIsLoading(true);
      try {
        const fileName = location.pathname.includes('/noticias') ? 'noticias.json' : 'testimonios.json';
        const response = await fetch(`https://cdn-redmaestra-endpoint.azureedge.net/frontend/${fileName}`);
        if (!response.ok) throw new Error('Failed to fetch item data');

        const data = await response.json();
        const selectedItem = data.find(item => item.id.toString() === itemId);

        setItemData(selectedItem || null);
      } catch (error) {
        console.error('Error loading item data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    loadItemData();
  }, [location.pathname, itemId]);

  // Format the date in "dd de month de yyyy" without changing it due to timezone issues
const formatDate = (dateStr) => {
  const [year, month, day] = dateStr.split('-');
  const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
  return `${parseInt(day)} de ${months[parseInt(month) - 1]} de ${year}`;
};


  // 6. Render Logic for Loading, Error, and Display
  if (isLoading) return <div className="loading-placeholder"><p>Procesando...</p></div>;
  if (!itemData) return <div className="loading-placeholder"><p>Procesando... Intente más tarde</p></div>;

  const imageUrl = itemData.image || 'https://cdn-redmaestra-endpoint.azureedge.net/static/media/default.png';

  // Inside the JSX for NovItem
const isTestimonio = location.pathname.includes('/testimonios'); // Check if the current path is for testimonios


  return (
    <div className="news">
      <div className="news-content">
        <h2>{itemData.title}</h2>
        <Badge className="date" bg="secondary">
          {formatDate(itemData.date)}
        </Badge>
        <hr/>
        
        <div className={`news-image ${isTestimonio ? 'testimonio' : 'noticia'}`} >
          <img src={imageUrl} alt={itemData.title} />
        </div>
        <ReactMarkdown>{itemData.body}</ReactMarkdown>
        {itemData.link && (
          <Button className="date btn btn-primary bl" bg="primary" href={itemData.link} target="_blank" rel="noopener noreferrer">
            Ingresa Aquí
          </Button>
        )}
      </div>
      <div className="volver">
        <button onClick={goBack} className="btn btn-primary bl">Volver</button>
      </div>
    </div>
  );
}

export default NovItem;
