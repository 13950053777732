import React from 'react';
import MainBanner from '../components/1. Main Banner/main_banner';
import Statistics from '../components/1. Statistics/statistics';
import ProgBanner from '../components/1. Program Banner/prog_banner';
import Companies from '../components/1. Companies/companies';
import Subscribe from '../components/1. Subscribe/subscribe';
import Redes from '../components/1. RRSS/rrss';
// import DonationPopup from '../components/1. Popup/donationPopup';

const componentMapping = {
  main_banner: MainBanner,
  statistics: Statistics,
  prog_banner: ProgBanner,
  companies: Companies,
  subscribe: Subscribe,
  rrss: Redes,
};

const HomePage = () => {
  return (
    <>
      {Object.entries(componentMapping).map(([id, Component]) => (
        <div key={id} id={id}>
          <Component />
        </div>
      ))}
      {/* <DonationPopup /> */}
    </>
  );
};

export default HomePage;
