import React, { useState } from 'react';
import './companies.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import the logos directly
import logoLipigas from '../../static/c_logos/logo-lipigas.png';
import logoMadeco from '../../static/c_logos/logo-madeco.png';
import logoSchneider from '../../static/c_logos/logo-Schneider-Electric.png';
import logoElectrafk from '../../static/c_logos/logo-electrafk.png';
import logoPolpaico from '../../static/c_logos/logo-polpaico.png';
import logoVinilit from '../../static/c_logos/logo-vinilit.png';
import logoUct from '../../static/c_logos/logo-uct.png';
import logoWcb from '../../static/c_logos/logo-wcb.png';

import logoInacap from '../../static/c_logos/logo-inacap.png';
import logoConstrumart from '../../static/c_logos/logo-construmart.png';
import logoGili from '../../static/c_logos/logo-gili.png';
import logoSocovesa from '../../static/c_logos/logo-socovesa.png';
import logoInfocap from '../../static/c_logos/logo-infocap.png';
import logoGuzman from '../../static/c_logos/logo-guzman.png';
import logoAconcap from '../../static/c_logos/logo-aconcap.png';
import logoRecabarren from '../../static/c_logos/logo-recabarren.png';
import logoEurofred from '../../static/c_logos/logo-eurofred.png';
import logoTarragona from '../../static/c_logos/logo-tarragona.png';
import logoMaestra from '../../static/c_logos/logo-maestra.png';
import logoAntofa from '../../static/c_logos/logo-antofa.png';
import logoLd from '../../static/c_logos/logo-ld.png';
import logoCecra from '../../static/c_logos/logo-cecra.png';
import logoH2o from '../../static/c_logos/logo-h2o.png';
import logoEsser from '../../static/c_logos/logo-esser.png';
import logoUsPlus from '../../static/c_logos/logo-us+.png';
import logoIuslatam from '../../static/c_logos/logo-iuslatam.png';
import logoCbre from '../../static/c_logos/logo-cbre.png';

const partnerLogos = [
  logoLipigas,
  logoMadeco,
  logoSchneider,
  logoElectrafk,
  logoPolpaico,
  logoVinilit,
  
  logoWcb,
];

const otherLogos = [
  logoInacap,
  logoUct,
  logoConstrumart,
  logoGili,
  logoSocovesa,
  logoInfocap,
  logoGuzman,
  logoAconcap,
  logoRecabarren,
  logoEurofred,
  logoTarragona,
  logoMaestra,
  logoAntofa,
  logoLd,
  logoCecra,
  logoH2o,
  logoEsser,
  logoUsPlus,
  logoIuslatam,
  logoCbre,
];

function Companies() {
  const [showMore, setShowMore] = useState(false); // State to control the visibility

  const settings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: 520,
      settings: {
        slidesToShow: 3
      }
    }]
  };

  return (
    <>
      <h1>Hemos trabajado con</h1>
      <div className="container">
        <Slider {...settings}>
          {partnerLogos.map((logo, index) => (
            <div className="slide" key={index}>
              <img src={logo} alt={`Logo ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
      <button className="companies-but" onClick={() => setShowMore(!showMore)}>
        {showMore ? 'ocultar' : 'ver más'}
      </button>
      {/* Conditional rendering based on showMore state */}
      {showMore && (
        <>
          <h2>También hemos trabajado con...</h2>
          <div className="container">
            <Slider {...settings}>
              {otherLogos.map((logo, index) => (
                <div className="slide" key={index}>
                  <img src={logo} alt={`Logo ${index}`} />
                </div>
              ))}
            </Slider>
          </div>
        </>
      )}
    </>
  );
}

export default Companies;
